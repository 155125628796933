/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://chqozkfmkbhvjcgjbe2dwh72zi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-y5adsjowwjhuxct2727qhfx4tq",
    "aws_cognito_identity_pool_id": "us-east-2:c4bd68c4-1620-4fc9-ac3b-27b648edfe17",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_rpF4Iinqi",
    "aws_user_pools_web_client_id": "1740scaahcib3ehar7m5id65i3",
    "oauth": {},
    "aws_user_files_s3_bucket": "meritcare33141-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
