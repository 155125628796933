import React, { useEffect, useState } from "react"
import theme from "../MyTheme"
import NumberFormat from "react-number-format"
import ReactDOM from "react-dom"
import { WithContext as ReactTags } from "react-tag-input"
import "./home.css"
import {
  allFacilities,
  addFacility,
  upgradeFacility,
  removeFacility,
} from "../api/providers"
import Amplify, { Storage } from "aws-amplify"
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

Storage.configure({
  AWSS3: {
    bucket: awsconfig.aws_user_files_s3_bucket,
    region: awsconfig.aws_user_files_s3_bucket_region,
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
  },
})

const Providers = (props) => {
  const [loading, setLoading] = useState(true)
  const [providers, setProviders] = useState([])
  const [isAddProvider, setIsAddProvider] = useState(false)
  const [type, setType] = useState("New")
  const [providerData, setProviderData] = useState({
    id: null,
    email: "",
    phone: "",
    facilityName: "",
    facilityDescription: "",
    facilityLogo: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
    website: "",
    staffUsers: "",
    emr: "",
    emrProviderId: "",
    privacyNoticeUrl: "",
    billingNoticeUrl: "",
    doctors: [],
    privacyFile: "",
    billingFile: "",
  })

  const [touched, setTouched] = useState({
    email: false,
    phone: false,
    facilityName: false,
    emr: false,
    emrProviderId: false,
    doctors: false,
    privacyNoticeUrl: false,
    billingNoticeUrl: false,
    privacyFile: false,
    billingFile: false,
  })

  useEffect(() => {
    getAllFacilitys()
  }, [props])

  const getAllFacilitys = () => {
    setLoading(true)
    allFacilities()
      .then((res) => {
        setProviders(res.data.listFacilitys.items)
        console.log("providers", res.data.listFacilitys.items)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleChange = (e) => {
    setProviderData({
      ...providerData,
      [e.target.name]: e.target.value,
    })
  }

  const onBlur = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    })
  }

  const handleAddition = (tag) => {
    setProviderData({
      ...providerData,
      doctors: [...providerData.doctors, tag],
    })
  }

  const handleDelete = (i) => {
    setProviderData({
      ...providerData,
      doctors: providerData.doctors.filter((doctor, index) => index !== i),
    })
  }

  const onClear = () => {
    setProviderData({
      id: null,
      email: "",
      phone: "",
      facilityName: "",
      facilityDescription: "",
      facilityLogo: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipcode: "",
      website: "",
      staffUsers: "",
      emr: "",
      emrProviderId: "",
      privacyNoticeUrl: "",
      billingNoticeUrl: "",
      doctors: [],
      privacyFile: "",
      billingFile: "",
    })
  }

  console.log("providerData", providerData)
  const onSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    setTouched({
      email: false,
      phone: false,
      facilityName: false,
      emr: false,
      emrProviderId: false,
      doctors: false,
      privacyNoticeUrl: false,
      billingNoticeUrl: false,
      privacyFile: false,
      billingFile: false,
    })
    if (type === "Edit") {
      if (providerData.privacyFile != "" && providerData.billingFile != "") {
        onProviderSubmit()
      } else if (
        providerData.privacyFile != "" &&
        providerData.billingFile == ""
      ) {

        onProviderConditionalSubmit(providerData.privacyFile, "privacyFile")
      } else if (
        providerData.privacyFile == "" &&
        providerData.billingFile != ""
      ) {

        onProviderConditionalSubmit(providerData.billingFile, "billingFile")
      } else {
        onUpdateFacility()
      }
    } else {
      onProviderSubmit()
    }
  }

  const onUpdateFacility = () => {
    let doctors1 = []
    doctors1 = providerData.doctors.map((item) => {
      return item.text
    })
    var obj = {
      id: providerData.id,
      email: providerData.email,
      phone: providerData.phone,
      facilityName: providerData.facilityName,
      emr: providerData.emr,
      emrProviderId: providerData.emrProviderId,
      doctors: doctors1,
    }
    upgradeFacility(obj)
      .then((res) => {
        getAllFacilitys()
        onClear()
        setIsAddProvider(false)
        setType("New")
        props.onUpdate()
      })
      .catch((err) => console.log("updateFacilityerror", err))
  }

  const onProviderConditionalSubmit = (file, filetype) => {
    let filename = Math.floor(Math.random() * 100) + "_" + file.name
    Storage.put(`providers/${filename}`, file, {
      level: "public",
      contentType: "application/pdf",
    }).then((res) => {
      let doctors1 = []
      doctors1 = providerData.doctors.map((item) => {
        return item.text
      })
      var obj = {
        id: providerData.id,
        email: providerData.email,
        phone: providerData.phone,
        facilityName: providerData.facilityName,
        emr: providerData.emr,
        emrProviderId: providerData.emrProviderId,
        doctors: doctors1,
        privacyNoticeUrl: filename,
        billingNoticeUrl: filename,
      }
      if (filetype == "privacyFile") delete obj.billingNoticeUrl
      else delete obj.privacyNoticeUrl
      upgradeFacility(obj)
        .then((res) => {
          getAllFacilitys()
          onClear()
          setIsAddProvider(false)
          setType("New")
          props.onUpdate()
        })
        .catch((err) => console.log("updateFacilityerror", err))
    })
  }

  const onProviderSubmit = () => {
    let filename = Math.floor(Math.random() * 100) + "_" + providerData.privacyFile.name
    Storage.put(
      `providers/${filename}`,
      providerData.privacyFile,
      {
        level: "public",
        contentType: "application/pdf",
      }
    )
      .then((result) => {
        // console.log(JSON.stringify(result))
        providerData.privacyNoticeUrl = filename
        let filename2 = Math.floor(Math.random() * 100) + "_" + providerData.billingFile.name
        Storage.put(
          `providers/${filename2}`,
          providerData.billingFile,
          {
            level: "public",
            contentType: "application/pdf",
          }
        )
          .then((resultBilling) => {
            providerData.billingNoticeUrl = filename2
            delete providerData.privacyFile
            delete providerData.billingFile
            delete providerData.staffUsers

            let doctors1 = []
            doctors1 = providerData.doctors.map((item) => {
              return item.text
            })
            let obj = {
              id: providerData.id,
              email: providerData.email,
              phone: providerData.phone,
              facilityName: providerData.facilityName,
              emr: providerData.emr,
              emrProviderId: providerData.emrProviderId,
              doctors: doctors1,
              privacyNoticeUrl: providerData.privacyNoticeUrl,
              billingNoticeUrl: providerData.billingNoticeUrl,
            }
            if (providerData.facilityName !== "" && providerData.email !== "") {
              if (type === "New") {
                addFacility(obj)
                  .then((res) => {
                    getAllFacilitys()
                    onClear()
                    setIsAddProvider(false)
                    props.onUpdate()
                  })
                  .catch((err) => console.log("AddFacilityerror", err))
              } else {
                upgradeFacility(obj)
                  .then((res) => {
                    getAllFacilitys()
                    onClear()
                    setIsAddProvider(false)
                    setType("New")
                    props.onUpdate()
                  })
                  .catch((err) => console.log("updateFacilityerror", err))
              }
            }
          })
          .catch((err) => console.log("billingFileerror", err))
      })
      .catch((err) => console.log("privacyFileerror", err))
  }

  const onUpdate = (provider) => {
    setType("Edit")
    let doctors1 = []
    doctors1 = provider.doctors.map((item) => {
      return { id: item, text: item }
    })
    setIsAddProvider(true)
    setProviderData({
      id: provider.id,
      email: provider.email,
      phone: provider.phone,
      facilityName: provider.facilityName,
      emr: provider.emr,
      emrProviderId: provider.emrProviderId,
      doctors: doctors1,
      privacyNoticeUrl: provider.privacyNoticeUrl,
      billingNoticeUrl: provider.billingNoticeUrl,
      privacyFile: "",
      billingFile: "",
    })
  }
  const onDelete = (id) => {
    window.confirm("Are you shure to delete") &&
      removeFacility(id)
        .then((res) => {
          getAllFacilitys()
          props.onUpdate()
        })
        .catch((err) => {
          console.log("err", err)
        })
  }
  const validate = () => {
    const errors = {}
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (providerData.facilityName === "") {
      errors.facilityName = "Please enter Name"
    }

    if (providerData.email) {
      if (!emailRegex.test(providerData.email)) {
        errors.email = "Please enter valid email"
      }
    } else {
      errors.email = "Please enter email"
    }

    if (providerData.phone === "") {
      errors.phone = "Please enter Phone Number"
    } else {
      if (providerData.phone.length < 12) {
        errors.phone = "Please enter Valid Phone Number"
      }
    }

    if (providerData.doctors.length === 0) {
      errors.doctors = "Please enter Doctor Names"
    }

    if (providerData.emr === "") {
      errors.emr = "Please enter Emr"
    }

    if (providerData.emrProviderId === "") {
      errors.emrProviderId = "Please enter Emr Provider Id"
    }
    if (type != "Edit") {
      if (providerData.privacyFile === "") {
        errors.privacyFile = "Please choose Privacy Notice Url"
      }
      if (providerData.billingFile === "") {
        errors.billingFile = "Please choose Billing Notice Url"
      }
    }

    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    }
  }

  const { errors, isValid } = validate()
  console.log("errors", errors)
  console.log("providerData", providerData)

  return (
    <div className="container">
      {isAddProvider ? (
        <div className="border p-2">
          {loading ? (
            <div className="col-sm-12 d-flex justify-content-center">
              <img src="images/loading.gif" height={40} width={40} />
            </div>
          )
            : (<div>
              <svg
                width="2em"
                height="1.5em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-left m-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setIsAddProvider(false)}
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <form className="row justify-content-center">
                <div className="row col-sm-12 justify-content-center">
                  <div className="col-sm-12 col-md-6 flex-column mb-2">
                    <label for="name" className="mt-3">
                      Name
                  </label>
                    <input
                      type="text"
                      readonly
                      className="form-control"
                      id="facilityName"
                      name="facilityName"
                      onBlur={onBlur}
                      value={providerData.facilityName}
                      placeholder="Name"
                      onChange={handleChange}
                    />
                    <p className="text-left  error">
                      {touched.facilityName && !!errors.facilityName && (
                        <span>{errors.facilityName}</span>
                      )}
                    </p>

                    <label for="email" className="mt-3">
                      Email
                  </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      onBlur={onBlur}
                      value={providerData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                    <p className="text-left  error">
                      {touched.email && !!errors.email && (
                        <span>{errors.email}</span>
                      )}
                    </p>

                    <label for="phone" className="mt-3">
                      Phone
                  </label>
                    <NumberFormat
                      id="phone"
                      value={providerData.phone}
                      className="form-control"
                      name="phone"
                      format="+1##########"
                      mask=""
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                      onBlur={onBlur}
                      required
                    />
                    <p className="text-left  error">
                      {touched.phone && !!errors.phone && (
                        <span>{errors.phone}</span>
                      )}
                    </p>

                    <label htmlFor="doctors" className="mt-3">
                      Add Doctors
                  </label>
                    <ReactTags
                      tags={providerData.doctors}
                      handleAddition={handleAddition}
                      handleDelete={handleDelete}
                      autofocus={false}
                      name="doctors"
                      delimiters={delimiters}
                      handleInputBlur={() =>
                        setTouched({ ...touched, doctors: true })
                      }
                      placeholder="Enter doctor names"
                      inputFieldPosition="top"
                      classNames={{
                        tagInputField: "form-control",
                        tag: "selected mt-2",
                        remove: "remove",
                      }}
                    />
                    <p className="text-left  error">
                      {touched.doctors && !!errors.doctors && (
                        <span>{errors.doctors}</span>
                      )}
                    </p>
                  </div>
                  <div className=" col-sm-12 col-md-6 flex-column mb-2">
                    <label for="emr" className="mt-3">
                      Emr
                  </label>
                    <select
                      id="emr"
                      name="emr"
                      onBlur={onBlur}
                      value={providerData.emr}
                      onChange={handleChange}
                      className="custom-select"
                    >
                      <option selected>Select</option>
                      <option value="Athene">Athena</option>
                      <option value="Calmed">Calmed</option>
                    </select>
                    <p className="text-left  error">
                      {touched.emr && !!errors.emr && <span>{errors.emr}</span>}
                    </p>

                    <label for="emrProviderId" className="mt-3">
                      Emr Practice ID
                  </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emrProviderId"
                      name="emrProviderId"
                      onBlur={onBlur}
                      value={providerData.emrProviderId}
                      placeholder="Emr Practice ID"
                      onChange={handleChange}
                    />
                    <p className="text-left  error">
                      {touched.emrProviderId && !!errors.emrProviderId && (
                        <span>{errors.emrProviderId}</span>
                      )}
                    </p>

                    <label for="privacyNoticeUrl" className="mt-3">
                      Privacy NoticeUrl
                  </label>
                    <input
                      required
                      type="file"
                      className="form-control"
                      accept="application/pdf"
                      name="privacyFile"
                      onBlur={onBlur}
                      onChange={(e) =>
                        setProviderData({
                          ...providerData,
                          privacyFile: e.target.files[0],
                        })
                      }
                    />
                    {type === "Edit" && (
                      <p className="text-left mt-1 existing">
                        {providerData.privacyNoticeUrl
                          ? "Existing File: " + providerData.privacyNoticeUrl
                          : "No Existing Files"}
                      </p>
                    )}

                    <p className="text-left  error">
                      {touched.privacyFile && !!errors.privacyFile && (
                        <span>{errors.privacyFile}</span>
                      )}
                    </p>

                    <label for="billingNoticeUrl" className="mt-3">
                      Billing NoticeUrl
                  </label>
                    <input
                      type="file"
                      required
                      className="form-control"
                      name="billingFile"
                      accept="application/pdf"
                      onFocus={() => setTouched({ ...touched, billingFile: true })}
                      onChange={(e) =>
                        setProviderData({
                          ...providerData,
                          billingFile: e.target.files[0],
                        })
                      }
                    />
                    {type === "Edit" && (
                      <p className="text-left mt-1 existing">
                        {providerData.billingNoticeUrl
                          ? "Existing File: " + providerData.billingNoticeUrl
                          : "No Existing Files"}
                      </p>
                    )}
                    <p className="text-left  error">
                      {touched.billingFile && !!errors.billingFile && (
                        <span>{errors.billingFile}</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="row col-sm-12 justify-content-center">
                  <button
                    onClick={onSubmit}
                    disabled={!isValid}
                    className="btn buttonStyle m-4"
                    style={{ backgroundColor: theme.colors.BRAND }}
                  >
                    {type} Provider
                </button>
                </div>
              </form>
            </div>)}


        </div>
      ) : (
          <div className="border p-3">
            <div className="row justify-content-between p-3">
              <h5>Manage Providers</h5>
              <button
                onClick={() => {
                  setIsAddProvider(true)
                  setType("New")
                  onClear()
                }}
                className="btn buttonStyle"
              >
                New Provider
              <i className="fa fa-plus ml-2"></i>
              </button>
            </div>
            {loading ? (
              <div className="col-sm-12 d-flex justify-content-center">
                <img src="images/loading.gif" height={40} width={40} />
              </div>
            ) : (
                <div className="providers tableDiv">
                  <table className="table table-bordered">
                    <thead className="">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {providers.map((provider) => {
                        return (
                          <tr key={provider.id}>
                            <td>{provider.facilityName}</td>
                            <td>{provider.phone}</td>
                            <td>{provider.email}</td>
                            <td>
                              <i
                                className="fa fa-pencil"
                                onClick={() => onUpdate(provider)}
                              ></i>
                            </td>
                            <td>
                              <i
                                className="fa fa-trash"
                                onClick={() => onDelete(provider.id)}
                              ></i>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        )}
    </div>
  )
}

export default Providers
