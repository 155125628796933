import React from "react"
import Amplify from "aws-amplify"
import { withAuthenticator,SignIn } from "aws-amplify-react"
import config from "../aws-exports"
import theme from "../MyTheme"
import "@aws-amplify/ui/dist/style.css"
import { useHistory } from "react-router-dom"
Amplify.configure(config)
const Authentication = (props) => {
    const history=useHistory()
  return <button onClick={
    props.getUser(),
    history.push("/home")
  }></button>
}
const MyTheme = {
  button: { backgroundColor: theme.colors.PRIMARY },
  signInButtonIcon: { display: "none" },
}

export default withAuthenticator(Authentication, false, [<SignIn/>], null, MyTheme, {})
