import { API, graphqlOperation } from "aws-amplify"
import config from "../aws-exports"
import { listEmrAppointments,  getEmrAppointment } from "../graphql/queries"
import {
    createEmrAppointment,
    updateEmrAppointment ,
    deleteEmrAppointment,
    sendAppointment
} from "../graphql/mutations"

API.configure(config)

export const addEmrAppointment = async (emrAppointment) => {
  return await API.graphql(
    graphqlOperation(createEmrAppointment, {
      input: emrAppointment,
    })
  )
}
export const upgradeEmrAppointment = async (emrAppointment) => {
  return await API.graphql(
    graphqlOperation(updateEmrAppointment , {
      input: emrAppointment,
    })
  )
}
export const fetchEmrAppointment = async (id) => {
  return await API.graphql(
    graphqlOperation( getEmrAppointment, {
      id: id,
    })
  )
}
export const allEmrAppointment = async (id) => {
  return await API.graphql(graphqlOperation(listEmrAppointments, { limit: 1000 }))
}
export const removeEmrAppointment = async (id) => {
  return await API.graphql(
    graphqlOperation(deleteEmrAppointment, { input: { id: id } })
  )
}

export const sentAppointmentNotification = async (userEmail,userPhone,message) => {
  return await API.graphql(
    graphqlOperation(sendAppointment, {
        userEmail,
        userPhone,
        message
    })
  )
}