import React, {useState, useEffect } from 'react'
import './home.css'
import config from "../aws-exports"
import Amplify, { Auth } from "aws-amplify"
import { withRouter,useHistory } from "react-router-dom"

Amplify.configure(config)

const Header=(props)=>{
    // const history=useHistory()
    const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn)
    useEffect(()=>{
        setIsLoggedIn(props.isLoggedIn)
    },[props])
    
    const onSignOut=()=>{
        Auth.signOut({ global: true })
          .then((res) => {
            setIsLoggedIn(false)
            props.getUser()
            props.history.push("/")
          })
          .catch((err) => console.log(err))
    }
    return (
      <div className="row p-0 m-0 col-sm-12 justify-content-center">
        <div className="col-md-4 mb-3"></div>
        <div
          className="col-sm-12 col-md-4 mb-3 "
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src="/logo.png" style={{ height: 100, width: 200 }} />
        </div>
        <div
          className="col-sm-12 col-md-4 mb-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {isLoggedIn&&<div className="align-self-center">
            <button className="btn buttonStyle" onClick={onSignOut}>Sign Out</button>
          </div>}
        </div>
      </div>
    )
}
export default withRouter(Header)