import React, { useState, useEffect } from "react";
import "./home.css";
import { allFacilities } from "../api/providers";
import { addEmrAppointment, sentAppointmentNotification } from "../api/emr";
import NumberFormat from "react-number-format";
import DateTimePicker from 'react-datetime-picker';

import moment from "moment";

function EmrSimulator(props) {
    const [doctors, setDoctors] = useState([])
    const [emrSimulator, setEmrSimulator] = useState([])
    const [emrSimulatorData, setEmrSimulatorData] = useState({
        id: null,
        providerId: "",
        patientId: "",
        patientPhone: "",
        patientEmail: "",
        purpose: "",
        doctorName: "",
        appointmentDate: "",
    })

    const [touched, setTouched] = useState({
        providerId: false,
        patientId: false,
        patientPhone: false,
        patientEmail: false,
        purpose: false,
        doctorName: false,
        appointmentDate: false,
    })

    console.log("emrSimulatorData", emrSimulatorData)
    useEffect(() => {
        getAllFacilities()
    }, [props])

    const getAllFacilities = () => {
        allFacilities()
            .then((res) => {
                setEmrSimulator(res.data.listFacilitys.items)
                console.log("res", res)
            })
            .catch((err) => {
                console.log("err", err)
            })
    }

    const onSelectProvider = (e) => {
        setEmrSimulatorData({
            ...emrSimulatorData,
            providerId: e.target.value
        })
        let provider = emrSimulator.find((emrpro) => emrpro.id == e.target.value)
        setDoctors(provider.doctors)
    }

    const onBlur = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true,
        })

    }
    const handleChange = (e) => {
        setEmrSimulatorData({
            ...emrSimulatorData,
            [e.target.name]: e.target.value,
        })
    }

    const onClear = (e) => {
        setEmrSimulatorData({
            id: null,
            providerId: "",
            patientId: "",
            patientPhone: "",
            patientEmail: "",
            purpose: "",
            doctorName: "",
            appointmentDate: "",
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()

        setTouched({
            providerId: false,
            patientId: false,
            patientPhone: false,
            patientEmail: false,
            purpose: false,
            doctorName: false,
            appointmentDate: false,
        })

        let obj = {
            id: null,
            providerId: emrSimulatorData.providerId,
            patientId: emrSimulatorData.patientId,
            patientPhone: emrSimulatorData.patientPhone,
            patientEmail: emrSimulatorData.patientEmail,
            purpose: emrSimulatorData.purpose,
            doctorName: emrSimulatorData.doctorName,
            appointmentDate: new Date(emrSimulatorData.appointmentDate),
        }
        addEmrAppointment(obj)
            .then((res) => {
                onClear()
                sentAppointmentNotification(obj.patientEmail, obj.patientPhone, `https://admin.merit-care.com/appt/${obj.providerId}/${obj.patientId}`)
                    .then((data) => {
                        alert("Appointment Notification has been sent")
                        console.log('Notifications sent ' + JSON.stringify(data))
                    })
                    .catch((err) => {
                        alert("Failed to send Appointment Notification")
                        console.log('Error sending notification ' + JSON.stringify(err))
                    });
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const validate = () => {
        const errors = {}

        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const dateRegex= /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/g;

        if (emrSimulatorData.patientId === "") {
            errors.patientId = "Please Enter patient Id"
        }
        if (emrSimulatorData.providerId === "") {
            errors.providerId = "Please Enter Provider Name"
        }

        if (emrSimulatorData.patientPhone === "") {
            errors.patientPhone = "Please enter Phone Number"
        } else {
            if (emrSimulatorData.patientPhone.length < 12) {
                errors.patientPhone = "Please enter Valid Phone Number"
            }
        }

        if (emrSimulatorData.patientEmail) {
            if (!emailRegex.test(emrSimulatorData.patientEmail)) {
                errors.patientEmail = "Please enter Valid Email"
            }
        } else { errors.patientEmail = "Please enter Email" }


        if (emrSimulatorData.purpose === "") {
            errors.purpose = "Please enter purpose"
        }

        if (emrSimulatorData.doctorName === "") {
            errors.doctorName = "Please enter Doctor Name"
        }

        if (dateRegex.test(moment(emrSimulatorData.appointmentDate).format("MM/DD/YYYY"))) {
            if (new Date(emrSimulatorData.appointmentDate) < new Date()) {
              errors.appointmentDate = "Please select only valid future Date and time"
            }
          }
          else{
            errors.appointmentDate = "Please enter Valid date and time"
          }


        return {
            errors,
            isValid: Object.keys(errors).length === 0,
        }
    }

    const { errors, isValid } = validate()
    console.log('errors', errors)
    console.log("Date",moment(emrSimulatorData.appointmentDate, 'MMDDMYY').format('YYYY-MM-DD').length)
    console.log("New DAte",new Date())

    const getDate = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        let date = yyyy + "-" + mm + "-" + "0" + dd
        return date.toString()
    }

    return (
        <div className="container border">
            <form className=" row justify-content-center">
                <div className="row col-sm-12 justify-content-center">
                    <div className="row col-sm-12 justify-content-center m-3">
                        <h5>Send Appointment</h5>
                    </div>
                    <div className="col-sm-6  justify-content-center flex-column mb-2">
                        <select
                            id="providerId"
                            name="providerId"
                            onBlur={onBlur}
                            onChange={onSelectProvider}
                            className="custom-select form mt-3"
                        >
                            <option value="">Select Provider</option>
                            {emrSimulator.map((emr) => {
                                return (
                                    <option
                                        selected={
                                            emrSimulatorData.providerId === emr.id ? "selected" : null
                                        }
                                        value={emr.id}
                                        key={emr.id}
                                    >
                                        {emr.facilityName}
                                    </option>
                                )
                            })}
                        </select>
                        <p className="text-left error">
                            {touched.providerId && !!errors.providerId && (
                                <span>{errors.providerId}</span>
                            )}
                        </p>

                        <input
                            type="text"
                            readonly
                            className="form-control form mt-3"
                            id="patientId"
                            name="patientId"
                            onBlur={onBlur}
                            value={emrSimulatorData.patientId}
                            placeholder="Enter Patient ID"
                            onChange={handleChange}
                        />
                        <p className="text-left error">
                            {touched.patientId && !!errors.patientId && (
                                <span>{errors.patientId}</span>
                            )}
                        </p>

                        <NumberFormat
                            id="patientPhone"
                            value={emrSimulatorData.patientPhone}
                            className="form-control form mt-3"
                            name="patientPhone"
                            format="+1##########"
                            mask=""
                            placeholder="Enter Phone Number"
                            onChange={handleChange}
                            onBlur={onBlur}
                            required
                        />
                        <p className="text-left  error">
                            {touched.patientPhone && !!errors.patientPhone && (
                                <span>{errors.patientPhone}</span>
                            )}
                        </p>

                        <input
                            type="text"
                            readonly
                            id="patientEmail"
                            name="patientEmail"
                            onBlur={onBlur}
                            className="form-control form mt-3"
                            value={emrSimulatorData.patientEmail}
                            placeholder="Enter email"
                            onChange={handleChange}
                        />
                        <p className="text-left error">
                            {touched.patientEmail && !!errors.patientEmail && (
                                <span>{errors.patientEmail}</span>
                            )}
                        </p>

                        <input
                            type="text"
                            readonly
                            id="purpose"
                            name="purpose"
                            onBlur={onBlur}
                            className="form-control form mt-3"
                            value={emrSimulatorData.purpose}
                            placeholder="Enter Purpose"
                            onChange={handleChange}
                        />
                        <p className="text-left error">
                            {touched.purpose && !!errors.purpose && (
                                <span>{errors.purpose}</span>
                            )}
                        </p>

                        <select
                            id="doctorName"
                            name="doctorName"
                            value={emrSimulatorData.doctorName}
                            onBlur={onBlur}
                            onChange={handleChange}
                            className="custom-select form mt-1">
                            <option selected>Select Doctor Name</option>
                            {doctors.map((doctor) => {
                                return <option id={doctor} value={doctor}>{doctor}</option>

                            })}
                        </select>
                        <p className="text-left error">
                            {touched.doctorName && !!errors.doctorName && (
                                <span>{errors.doctorName}</span>
                            )}
                        </p>
                       
                        <div className="form-control">
                            <DateTimePicker
                                onChange={(e) =>
                                    
                                    {
                                        console.log("event", e)
                                        setEmrSimulatorData({ ...emrSimulatorData, appointmentDate: e })
                                }}
                                value={emrSimulatorData.appointmentDate}
                                name="appointmentDate"
                                format="MM/dd/yyyy hh:mm a"
                                clearIcon={null}
                                onCalendarOpen={() => setTouched({ ...touched, appointmentDate: true })}
                                className="react-datetime-picker__wrapper"
                                disableClock={false}
                                minDate={new Date()}
                                onCalendarClose={()=>console.log("value", emrSimulatorData.appointmentDate)}
                            />
                        </div>
                        <p className="text-left error">
                            {touched.appointmentDate && !!errors.appointmentDate && (
                                <span>{errors.appointmentDate}</span>
                            )}
                        </p>
                    </div>
                </div>

                <div className="row col-sm-12 justify-content-center">
                    <button
                        onClick={onSubmit}
                        disabled={!isValid}
                        className="btn buttonStyle m-4"
                    >
                        Send
          </button>
                </div>
            </form>
        </div>
    )
}

export default EmrSimulator