import React, { useState, useEffect } from "react"
import Amplify, { Auth } from "aws-amplify"
// import { Authenticator, withAuthenticator } from "aws-amplify-react"
import config from "./aws-exports"
import "@aws-amplify/ui/dist/style.css"
import "./App.css"
import Authentication from "./components/Authentication"
import Home from "./components/Home"
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import Header from "./components/Header"
import Appointment from "./components/Appointment"
Amplify.configure(config)

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    getUser()
  }, [])
  const getUser = () => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res.attributes.email_verified) {
          setIsLoggedIn(res.attributes.email_verified)
        } else {
          setIsLoggedIn(false)
        }
      })
      .catch((err) => {
        setIsLoggedIn(false)
        console.log(err)  
       })
  }
  return (
    <div className="app ">
      <Router>
        <Header getUser={getUser} isLoggedIn={isLoggedIn} />
        <Switch>
          <Route exact path="/">
            {<Authentication getUser={getUser} />}
          </Route>
          {isLoggedIn && <Route path="/home">{() => <Home />}</Route>}

          <Route exact path="/appt/:providerid/:patientid">
            <Appointment/>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
export default App
