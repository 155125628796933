import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { allEmrAppointment } from "../api/emr";

function Appointment(props) {
  const { providerid, patientid } = useParams();
  return (
    <div className="container border">
      <h1>Your Appointment</h1>
      <p>
        Your appointment has been scheduled. You can view your appointment
        details by clicking either link to view your appointment in either on
        our app or website. You must open this page in your mobile device for
        app link to work.
      </p>
      <a href={`meritcare:///?providerId=${providerid}&patientId=${patientid}`}>
        Open in MeritCare App
      </a>
      <br />
      <br />
      <a
        href={`https://app.merit-care.com/?providerId=${providerid}&patientId=${patientid}`}
      >
        Open in MeritCare Website
      </a>
    </div>
  );
}

export default Appointment;
