import React, { useState } from "react";
import EmrSimulator from "./EmrSimulator";
import "./home.css";
import Providers from "./Providers";
import Users from "./Users";
const Home = () => {
  const [index, setIndex] = useState(0);

  return (
    <div className="container">
      <nav>
        <div className="nav nav-pills" id="nav-tab" role="tablist">
          <a
            className="nav-item nav-link border active col-sm-4 justify-content-center"
            id="nav-providers-tab"
            data-toggle="tab"
            href="#nav-providers"
            role="tab"
            aria-controls="nav-providers"
            aria-selected="true"
          >
            Providers
          </a>
          {/* <a
            className="nav-item nav-link border col-sm-3"
            id="nav-tasks-tab"
            data-toggle="tab"
            href="#nav-tasks"
            role="tab"
            aria-controls="nav-tasks"
            aria-selected="false"
           

          >
            Task Category
          </a> */}
          <a
            className="nav-item nav-link border col-sm-4"
            id="nav-users-tab"
            data-toggle="tab"
            href="#nav-users"
            role="tab"
            aria-controls="nav-users"
            aria-selected="false"
          >
            Users
          </a>
          <a
            className="nav-item nav-link border col-sm-4"
            id="nav-emrsimulator-tab"
            data-toggle="tab"
            href="#nav-emrsimulator"
            role="tab"
            aria-controls="nav-emrsimulator"
            aria-selected="false"
          >
            Emr Simulator
          </a>
        </div>
      </nav>

      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active mt-5"
          id="nav-providers"
          role="tabpanel"
          aria-labelledby="nav-providers-tab"
        >
          <Providers onUpdate={() => setIndex(index + 1)} />
        </div>
        {/* <div
          className="tab-pane fade mt-5"
          id="nav-tasks"
          role="tabpanel"
          aria-labelledby="nav-tasks-tab"
        >
          <Tasks />
        </div> */}
        <div
          className="tab-pane fade mt-5"
          id="nav-emrsimulator"
          role="tabpanel"
          aria-labelledby="nav-emrsimulator-tab"
        >
          <EmrSimulator index={index} />
        </div>
        <div
          className="tab-pane fade mt-5"
          id="nav-users"
          role="tabpanel"
          aria-labelledby="nav-users-tab"
        >
          <Users />
        </div>
      </div>
    </div>
  );
};

export default Home;
