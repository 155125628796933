import { API, graphqlOperation } from "aws-amplify"
import config from "../aws-exports"
import { listUsers, getUser } from "../graphql/queries"
import {
    createUser,
    updateUser,
    deleteUser,
} from "../graphql/mutations"

API.configure(config)

export const addUser= async (user) => {
  return await API.graphql(
    graphqlOperation(createUser, {
      input: user,
    })
  )
}
export const upgradeUser = async (user) => {
  return await API.graphql(
    graphqlOperation(updateUser, {
      input: user,
    })
  )
}
export const fetchUser = async (id) => {
  return await API.graphql(
    graphqlOperation(getUser, {
      id: id,
    })
  )
}
export const allUser= async (id) => {
  return await API.graphql(graphqlOperation(listUsers, { limit: 1000 }))
}
export const removeUser= async (id) => {
  return await API.graphql(
    graphqlOperation(deleteUser, { input: { id: id } })
  )
}
