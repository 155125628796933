import React, { useState, useEffect } from 'react'
import { allUser } from '../api/users'

function Users() {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = () => {
        allUser()
            .then((res) => {
                setUserData(res.data.listUsers.items)
                console.log('res', res)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    return (
        <div className="container border">
            <div className="m-3"><h5>User Data</h5></div>

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Registered On</th>
                    </tr>
                </thead>
                <tbody>
                    {userData.map((user) => {
                        return (
                            <tr key={user.id}>
                                <td>{user.firstName}</td>
                                <td>{user.email}</td>
                                <td>{new Date(user.createdAt).toLocaleDateString('en-US')}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

        </div>
    )
}

export default Users
