export default {
  colors: {
    DEFAULT: "#DCDCDC",
    PRIMARY: "#1E3E72",
    LABEL: "#FE2472",
    INFO: "#00BCD4",
    ERROR: "#F44336",
    SUCCESS: "#4CAF50",
    WARNING: "#FF9800",
    MUTED: "#979797",
    INPUT: "#DCDCDC",
    ACTIVE: "#1E3E72",
    BUTTON_COLOR: "#1E3E72",
    PLACEHOLDER: "#FFFFFF",
    SWITCH_ON: "#1E3E72",
    SWITCH_OFF: "#D4D9DD",
    GRADIENT_START: "#00BCD4",
    GRADIENT_END: "#AC2688",
    PRICE_COLOR: "#EAD5FB",
    BORDER_COLOR: "#E7E7E7",
    BLOCK: "#E7E7E7",
    CAPTION: "#4A4A4A",
    INPUT_TEXT: "#1E3E72",
    BRAND: "#00dbad",
  },
  SIZES: {
    BLOCK_SHADOW_RADIUS: 2,
  },
}
